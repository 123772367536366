/**
 * Merges multiple objects into one. Does not modify any of the objects passed.
 *
 * @param {...Object} opts
 * @return {Object}
 */
export function merge(...opts) {
    return Object.assign({}, ...opts);
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * @param {Function} fn
 * @param {number} wait
 * @param {boolean} immediate
 * @return {Function}
 */
export function debounce(fn, wait, immediate = false) {
    let timeout;

    return function () {
        let args = arguments;
        let later = () => {
            timeout = null;

            if (!immediate) {
                fn.apply(this, args);
            }
        };
        let callNow = immediate && !timeout;

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);

        if (callNow) {
            fn.apply(this, args);
        }
    };
}

/**
 * Returns elements grouped by their y-axis (distance from the top of the
 * page).
 */
export function groupByYAxis(els) {
    const groups = {};

    els.forEach(el => {
        const rect = el.getBoundingClientRect();
        const y = rect.top.toString();

        if (!(y in groups)) {
            groups[y] = [];
        }

        groups[y].push(el);
    });

    return groups;
}

/**
 * Returns the maximum height of the supplied nodes.
 *
 * @param {Node[]} els
 * @return {number}
 */
export function getMaxHeight(els) {
    return Math.max.apply(null, els.map(el => el.offsetHeight));
}
