import * as dom from './dom';
import { Swiper, Util as SwiperUtil } from './swiper';
import Tabs from './tabs';

export default class Range {
    constructor(el) {
        this.container = SwiperUtil.createContainer(el, dom.get('.js-tab', el));
        this.swiper = null;
        this.tabs = new Tabs(this.container);
        this.mq = matchMedia('(max-width: 739px)');
        this.mq.addListener(this.decide.bind(this));
        this.decide();
    }

    decide() {
        if (this.mq.matches) {
            this.init();
        } else {
            this.destroy();
        }
    }

    isActive() {
        return this.swiper instanceof Swiper;
    }

    init() {
        if (this.isActive()) {
            return;
        }

        this.swiper = new Swiper(this.container, {
            slidesPerView: 'auto',
            centeredSlides: true,
            on: {
                slideChange: this.handleSlideChange.bind(this),
            }
        });
        this.container.classList.add('is-swiper-active');
    }

    destroy() {
        if (!this.isActive()) {
            return;
        }

        this.swiper.destroy();
        this.swiper = null;
        this.container.classList.remove('is-swiper-active');
    }

    handleSlideChange() {
        const slide = this.swiper.slides[this.swiper.activeIndex];
        const link = slide.querySelector('a');

        this.tabs.show(Tabs.hashToId(link.hash));
    }
}
