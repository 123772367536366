export function get(selector, container = document) {
    return Array.from(container.querySelectorAll(selector));
}

export function getFocusableChildren(context) {
    const selectors = [
        'a[href]',
        'area[href]',
        'input:not([disabled])',
        'textarea:not([disabled])',
        'select:not([disabled])',
        'button:not([disabled])',
        'audio',
        'video',
        'iframe',
        '[tabindex]',
    ];

    return get(selectors.join(', '), context);
}

export function empty(node) {
    while (node.hasChildNodes()) {
        node.removeChild(node.firstChild);
    }
}
