import A11yDialog from 'a11y-dialog';
import * as DOM from './dom';

export default class ChoiceConfirmation {

    /**
     * @param {HTMLFormElement} form
     * @param {HTMLElement}     dialog
     */
    constructor(form, dialog) {
        this.form = form;

        this.dialog = new A11yDialog(dialog);
        this.dialog.on('hide', () => {
            this.form.reset();
        });

        this.choiceInput = dialog.querySelector('input.js-input');
        this.choiceFigure = dialog.querySelector('.js-figure');
        this.footer = dialog.querySelector('.js-footer');

        this.handleInput = this.handleInput.bind(this);

        this.form.querySelector('.js-submit').setAttribute('hidden', 'hidden');
        this.form.addEventListener('change', this.handleInput, true);
        this.form.addEventListener('submit', event => event.preventDefault());

        this.moveExtraFields();
    }

    handleInput(event) {
        const choice = event.target.value;
        const figure = this.getFigure(choice);

        this.updateFigure(figure);
        this.choiceInput.value = choice;
        this.dialog.show();
    }

    updateFigure(newFigure) {
        newFigure.classList.remove('gallery__figure');
        newFigure.classList.add('figure--large');

        DOM.empty(this.choiceFigure);

        this.choiceFigure.appendChild(newFigure);
    }

    getFigure(value) {
        return this.form.querySelector(`.js-choice-figure[data-value='${value}']`).cloneNode(true);
    }

    moveExtraFields() {
        const container = this.form.querySelector('.js-extra-fields');

        while (container.hasChildNodes()) {
            this.footer.appendChild(container.firstChild);
        }
    }

    static init(form) {
        if (!form.hasAttribute('data-dialog-el')) {
            throw new Error('The "data-dialog-el" attribute is required on the form.');
        }

        const id = form.getAttribute('data-dialog-el');
        const dialog = document.getElementById(id);

        if (dialog === null) {
            throw new Error(`The dialog element "${id}" does not exist.`);
        }

        return new ChoiceConfirmation(form, dialog);
    }
}
