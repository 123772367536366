import { get, getFocusableChildren } from './dom';
import { merge } from './util';

export const defaultConfig = {
    defaultTabIndex: 0,
    enableHistory: true,
    tabSelector: container => {
        return get('a', container);
    }
};

export default class Tabs {
    constructor(container, config = {}) {
        Tabs.initContainer(container);
        this.config = merge(defaultConfig, config);

        this.tabs = this.config.tabSelector(container);
        this.tabs.forEach(Tabs.initTab);

        this.panels = this.tabs.map(tab => {
            return document.getElementById(Tabs.getPanelId(tab));
        });
        this.panels.forEach(Tabs.initPanel);

        this.tabs.forEach(tab => {
            tab.addEventListener('click', this.handleClick.bind(this));
        });

        window.addEventListener('hashchange', this.handleHashChange.bind(this));
        this.handleHashChange();
    }

    handleClick(event) {
        if (this.tabs.indexOf(event.target) === -1) {
            return;
        }

        history[this.config.enableHistory ? 'pushState' : 'replaceState'](null, null, event.target.hash);
        event.preventDefault();

        const panel = this.handleHashChange();

        if (panel) {
            Tabs.focus(panel);
        }

        return panel;
    }

    handleHashChange() {
        const id = Tabs.hashToId(window.location.hash);

        if (window.location.hash && this.isValidId(id)) {
            return this.show(id);
        }

        return this.showDefault();
    }

    isValidId(id) {
        return this.panels.map(panel => panel.id).indexOf(id) !== -1;
    }

    showDefault() {
        return this.show(Tabs.hashToId(this.tabs[this.config.defaultTabIndex].hash));
    }

    show(id) {
        if (!this.isValidId(id)) {
            return;
        }

        this.tabs.forEach(tab => {
            tab.setAttribute('aria-selected', Tabs.getPanelId(tab) === id);
        });

        let selectedPanel;

        this.panels.forEach(panel => {
            const isSelected = panel.id === id;

            if (isSelected) {
                panel.removeAttribute('hidden');
                selectedPanel = panel;
            } else {
                panel.setAttribute('hidden', '');
            }
        });

        return selectedPanel;
    }

    static focus(el) {
        const focusable = getFocusableChildren(el);

        if (focusable.length === 0) {
            return;
        }

        focusable[0].focus();
    }

    static initContainer(el) {
        el.setAttribute('role', 'tablist');
    }

    static initTab(el) {
        el.setAttribute('role', 'tab');
        el.setAttribute('aria-controls', Tabs.getPanelId(el));
    }

    static initPanel(el) {
        el.setAttribute('role', 'tabpanel');
        el.setAttribute('hidden', '');
    }

    static getPanelId(link) {
        return Tabs.hashToId(link.hash);
    }

    static hashToId(hash) {
        return hash.replace(/^#/, '');
    }
}
