import 'core-js/fn/array/from';
import 'core-js/fn/object/assign';

import 'svgxuse';
import * as DOM from './js/dom';
import ChoiceConfirmation from './js/choice-confirmation';
import Range from './js/range';

import './scss/frontend.scss';

// Tabs slider
DOM.get('.js-tabs').map(el => {
    return new Range(el);
});

// Prize choice confirmation
DOM.get('form.js-confirm-choice').forEach(form => {
    return ChoiceConfirmation.init(form);
});
