import Swiper from 'swiper/dist/js/swiper';

export { Swiper };

export class Util {
    static createContainer(el, slides) {
        el.classList.add('swiper-container');
        el.appendChild(Util.createWrapper(slides));

        return el;
    }

    static createWrapper(slides) {
        const wrapper = document.createElement('div');
        wrapper.classList.add('swiper-wrapper');

        slides.map(Util.createSlide).forEach(slide => {
            wrapper.appendChild(slide);
        });

        return wrapper;
    }

    static createSlide(slide) {
        slide.classList.add('swiper-slide');

        return slide;
    }
}
